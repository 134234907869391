const get_image_url_from_FS = (preview) => {
    if(preview && window.FS.root.contents[preview]){
        const imageBlob = new Blob([window.FS.root.contents[preview].contents]);

        // Create a URL for the Blob
        let url = (URL.createObjectURL(imageBlob));
        return url
    }
    return '';
}

export const get_image_src = (img_src, fallback_url='/resources/images/site_image.jpeg') => {
    return img_src?(img_src.includes('resources/')?(img_src):(window.Module.API.server_path+img_src)) : fallback_url;
}

export default get_image_url_from_FS;