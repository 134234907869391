import React, { useEffect, useState } from "react";
import { send_analytics } from "../../utils/send_analytics";
import { COLORS } from "../../utils/color";
import { Ping } from "../Animations";
import NavigationInfo from "./NavigationInfo";
import { useTranslation } from "react-i18next";

const PanelEmptyState = ({ perspective }) => {

    const {t} = useTranslation();
    const seller_specific = window.is_seller_specific();
    const mes_editor = window.is_mes_editor();
    
    const get_seller_sku_type = () => {
		if(window['Module'] && window['Module']['get_active_seller_element_display_name']){
			return window.Module.get_active_seller_element_display_name();
		}

		return '';
	}

	const get_seller_host_type = () => {
		if(window['Module'] && window['Module']['get_active_seller_element_host_display_name']){
			return window.Module.get_active_seller_element_host_display_name();
		}

		return '';
	}

	const is_seller_component_a_user_element = () => {
        return window.Module && window.Module.is_seller_component_a_user_element();
    }

	const newReleaseFound = () => {
		var active_design = window.Module.get_active_design();
		let new_release_found = active_design.release_update;
		active_design.delete()
		return new_release_found;
	}

	const openReleaseNotesModal = async() => {
		send_analytics({category: "Whats new clicked"})
		let resp = await window.Promisify(window.Module.setReleaseNotesModal())
        if(window.handle_ui_response){
            window.handle_ui_response(resp)
        }
	}

    return (
        <div style={{ minHeight: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', color: COLORS.gray5, backgroundColor: COLORS.gray3, padding: '12px' }}>

            <div style={{ padding: '12px', background: 'white', width: '100%', display: seller_specific ? 'flex' : 'none', flexDirection: 'column', justifyContent: 'space-between' }}>
                <div style={{ fontSize: '14px', width: '100%', flex: 1, textAlign: 'left', color: COLORS.gray6 }}>
                    <div>{t(`SKU Type:`)}</div>
                    <div className='flex_property' style={{ justifyContent: 'space-between' }}>
                        <div style={{ fontWeight: 'bold' }}>{get_seller_sku_type()}</div>
                    </div>
                    {is_seller_component_a_user_element() && get_seller_host_type() != "standalone" ? (
                        <React.Fragment>
                            <div style={{ marginTop: 5 }}>Host Type:</div>
                            <div className='flex_property' style={{ justifyContent: 'space-between' }}>
                                <div style={{ fontWeight: 'bold' }}>{get_seller_host_type()}</div>
                            </div>
                        </React.Fragment>
                    ) : ''}
                </div>
            </div>

            <div className='flex_property inf-mb-2' style={{ width: '100%', gap: '8px', display: seller_specific || mes_editor ? 'none' : '' }}>
                <div className='cp' style={{ color: '#0078ff', textDecoration: 'underline' }} onClick={openReleaseNotesModal}>What's new?</div>
                {newReleaseFound() ? <Ping size={8} /> : ''}
            </div>

            <NavigationInfo perspective={perspective} />

            <div className='view_port_info_container' style={{ display: perspective != '3d' ? 'block' : 'none', width: '100%' }}>
                <div className='view_port_text_style_2' style={{ fontSize: '14px' }}>Move selected object by&nbsp;<b>1mm</b></div>
                <div className='view_port_row'>
                    <div className='view_port_shortcut'>
                        <div className='view_port_icons'>
                            <img src='/resources/panel_icons_new/up.svg' />
                            <div className='view_port_text_under_icon'>{t(`Up`)}</div>
                        </div>
                        <div className='view_port_icons'>
                            <img src='/resources/panel_icons_new/down.svg' />
                            <div className='view_port_text_under_icon'>{t(`Down`)}</div>
                        </div>
                        <div className='view_port_icons'>
                            <img src='/resources/panel_icons_new/left.svg' />
                            <div className='view_port_text_under_icon'>{t(`Left`)}</div>
                        </div>
                        <div className='view_port_icons'>
                            <img src='/resources/panel_icons_new/right.svg' />
                            <div className='view_port_text_under_icon'>{t(`Right`)}</div>
                        </div>
                    </div>
                </div>

                <div className='view_port_text_style_2' style={{ fontSize: '14px' }}>Move selected object by&nbsp;<b>10mm</b></div>
                <div className='view_port_row'>
                    <div className='view_port_shortcut'>
                        <img src='/resources/panel_icons_new/shift key.svg' className='view_port_icons' style={{ paddingBottom: '12px' }} />
                        <img src='/resources/panel_icons_new/plus.svg' className='view_port_icons' style={{ paddingBottom: '12px' }} />
                        <div className='view_port_icons'>
                            <img src='/resources/panel_icons_new/up.svg' />
                            <div className='view_port_text_under_icon'>{t(`Up`)}</div>
                        </div>
                        <div className='view_port_icons'>
                            <img src='/resources/panel_icons_new/down.svg' />
                            <div className='view_port_text_under_icon'>{t(`Down`)}</div>
                        </div>
                        <div className='view_port_icons'>
                            <img src='/resources/panel_icons_new/left.svg' />
                            <div className='view_port_text_under_icon'>{t(`Left`)}</div>
                        </div>
                        <div className='view_port_icons'>
                            <img src='/resources/panel_icons_new/right.svg' />
                            <div className='view_port_text_under_icon'>{t(`Right`)}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PanelEmptyState