import { Row, Input, Col} from 'reactstrap';
import React, { useState, useEffect } from 'react';
import {COLORS} from '../../utils//color.js'
import LazyLoad from 'react-lazyload';
import sort_by from '../../utils/sort_by.js'
import repopulate_panel from '../../utils/panel_repopulation';
import repopulate_panel_pop_up from '../../utils/panel_pop_up_repopulation';
import { useAlert } from 'react-alert'
import build_path from '../../utils/build_path.js';
import { Tooltip } from 'antd';
import Icons from '../Icons/index.js';

const CarouselImageCard = ({group_name, sku_json, style={}, callbackOnSkuChange, ...props}) => {
	const [ selected_sku, set_selected_sku ] = useState('');

	const onChangeSelectedSku = (selected_sku_id) => {
		if(sku_json){
			let selectedSku = sku_json.find(sku => sku.id === selected_sku_id);
			if(selectedSku){
				set_selected_sku(selectedSku);
			}
		}
	}

	useEffect(() => {
		if(callbackOnSkuChange){
			callbackOnSkuChange(selected_sku)
		}
	}, [selected_sku]);

	useEffect(() => {
		if(sku_json && sku_json.length){
			set_selected_sku(sku_json[0])
		}
	}, [sku_json]);
	
	return (
		// <div className='flex_column inf-p-2' style={{...style, gap: '8px', cursor: 'pointer', textAlign: 'left'}} {...props}>
		// 	<div className='inf-flex inf-justify-between' style={{gap: '8px'}}>
		// 		<div className='lines1_elipsis inf-font-bold' style={{flex: 1, minWidth: '24px'}}>{group_name}</div>
		// 		{
		// 			sku_json && sku_json.length && sku_json.length > 0 ?
		// 			<div style={{color: 'var(--inf-theme-gray-500)'}}>({sku_json.length})</div>
		// 			: ''
		// 		}
		// 	</div>
		// 	<div className='scale_105 flex_center' style={{ transformOrigin: 'bottom' , flex: 1, minHeight: '0px'}}>
		// 		<img
		// 			// style={{ ...default_image_style }}
		// 			src={selected_sku && selected_sku.display_pic && selected_sku.display_pic.image ? build_path(window.Module.API.get_assets_path(), selected_sku.display_pic.image) : '/resources/images/INFURNIA LOGO white-10.svg'}
		// 			alt={'Image Not Found'}
		// 			style={{height: '100%', maxWidth: '100%'}}
		// 			// onClick={on_click}
		// 		/>
		// 	</div>
		// 	<div style={{ fontSize: '12px', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} className='flex_center'>
		// 		<Input style={{ display: sku_json && sku_json.length ? 'block' : 'none', fontSize: '12px', padding: '4px', borderRadius: '0px', color: 'black', maxWidth: '100%' }} type="select" value={selected_sku && selected_sku.id} onClick={(e) => e.stopPropagation()} onChange={(e) => onChangeSelectedSku(e.target.value)}>
		// 			{
		// 				sku_json && sku_json.length ? sku_json.sort(sort_by).map((sku, idx) => (
		// 					<option key={idx} value={sku.id}>{sku.name ? sku.name : 'No Name'}</option>
		// 				))
		// 					: ''
		// 			}
		// 		</Input>
		// 	</div>
		// </div>

		<div className='inf_carousel_card inf-flex inf-text-xs' style={{...style}} {...props}>
			<Tooltip placement='topLeft' title={
				<div>
					<div className='inf-mb-2' style={{whiteSpace: 'wrap'}}>{selected_sku && selected_sku.name ? selected_sku.name : "No Name"}</div>
					<img
						style={{ maxHeight: '200px', maxWidth: '100%' }}
						src={selected_sku && selected_sku.display_pic && selected_sku.display_pic.image ? build_path(window.Module.API.get_assets_path(), selected_sku.display_pic.image) : '/resources/images/fallback_image_thumbnail.svg'}
						alt={'Image Not Found'}
					/>
				</div>
			} mouseEnterDelay={0.5}>
			<div className='inf_carousel_card_image_container'>
				<img
					// style={{ ...default_image_style }}
					src={selected_sku && selected_sku.display_pic && selected_sku.display_pic.image ? build_path(window.Module.API.get_assets_path(), selected_sku.display_pic.image) : '/resources/images/fallback_image_thumbnail.svg'}
					alt={'Image Not Found'}
					style={{height: '100%', width: '100%', objectFit: selected_sku.sku_division_id === "finish" ? 'cover' : 'contain'}}
					// onClick={on_click}
				/>
			</div>
			</Tooltip>
			<div className='flex_column inf-justify-between' style={{flex:2, minWidth: '0px'}}>
				<div className='inf-flex inf-justify-between inf-py-2 inf-px-2' style={{gap: '8px'}}>
					<div className='lines2_elipsis' style={{flex: 1, minWidth: '24px', whiteSpace: 'break-spaces', fontWeight: 500}}>{group_name}</div>
					{
						sku_json && sku_json.length && sku_json.length > 0 ?
						<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '4px', marginTop: '-4px'}}>
							<Icons name={(selected_sku && selected_sku.catalogue_type) == 'org' ? 'organisation' : (selected_sku && selected_sku.catalogue_type) == 'user' ? 'design_user' : 'world'} />
							<div className='inf_carousel_group_sku_count'>{sku_json.length}</div>
						</div>
						: ''
					}
				</div>
				
				{/* <div style={{ fontSize: '12px', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} className='flex_center'> */}
					
				{
					sku_json && sku_json.length ?
						<div className='inf_carousel_card_sku_selector_container'>
							<Input className='inf_carousel_card_sku_selector' style={{ fontSize: '12px', fontWeight: '300', height: '24px', padding: '2px 8px', borderRadius: '0px', color: 'black', maxWidth: '100%', height: 'unset', background: 'transparent', border: 'none' }} type="select" value={selected_sku && selected_sku.id} onClick={(e) => e.stopPropagation()} onChange={(e) => onChangeSelectedSku(e.target.value)}>
								{
									sku_json && sku_json.length ? sku_json.sort(sort_by).map((sku, idx) => (
										<option key={idx} value={sku.id}>{sku.name ? sku.name : 'No Name'}</option>
									))
										: ''
								}
							</Input>
						</div>
						: ''
				}
				<div className='inf_carousel_card_sku_count_placeholder'>
					{`${sku_json.length} ${sku_json.length > 1 ? "SKUs" : "SKU"}`}
				</div>
				{/* </div> */}
			</div>
		</div>
	)
}

const CarouselImageBox = ({ panel_pop_up_show, set_page_loader, sku_json, handle_ui_response, update_view, show_preview_loader, route, route_data, name, idx, container_style, overlay_style, title_style, disable_overlay, footer_text, carousel_minimize, update_carousel_minimize, perspective}) => {
	try {

		const alert = useAlert();
		
		// const [ selected_sku, set_selected_sku ] = useState('')
		const [ selected_sku_id, set_selected_sku_id ] = useState('') 


		// const default_container_style = {
		// 	textAlign:'-webkit-center',
    	// 	background: 'white',
		//     // borderWidth: '0px 1px',
    	// 	// borderColor: 'black',
    	// 	// borderStyle: 'solid',
		// 	// height: '100%',
			
		// };

		// const default_image_style = {
		// 	borderRadius:6 ,
		// 	cursor: 'pointer',
		// 	height: '120px',
		// 	width:'auto',
		// 	maxWidth:'100%',
		// 	marginTop:'10px',
		// };

		// const xyz = (e) => {
		// 	set_selected_sku_id(e.target.value)
		// }

		// const populate_panel_search = async(search_string) => {
		// 	// var active_design = window.Module.get_active_design();
		// 	// var active_view = active_design.active_view;
		// 	var promise_resp;
		// 	if(window.Module){
		// 		try{
		// 			promise_resp = window.Module.populate_panel_search(JSON.stringify({"search": search_string}));
		// 		}
		// 		catch(err){
		// 			// alert.error("Something went wrong with search")
		// 			return
		// 		}
		// 	}
		
		// 	var ui_response = await window.Promisify(promise_resp);
		// 	handle_ui_response(ui_response);
		
		// 	update_view()
		
		// }	

		const onclick_close_carousel = () => {
			// set_search_string("");
			var active_design = window.Module.get_active_design();
			var active_view = active_design.active_view;
			active_view.hide_carousel();
			update_view();
			active_design.delete();
			active_view.delete();
		}

		const on_click = async (e) => {
			if (route == 'dummy_carousel_onclick') {
				//do something about parameter modal
				console.log('trigger add sku to parameter json')
				window.set_sku_value(selected_sku_id)
				onclick_close_carousel()
			} else {
				set_page_loader({
					show: true,
					text: 'Please Wait...'
				})
				setTimeout(async () => {
					var promise_resp;
					try {
						if (route_data == "") {
							if (window.debug_mode) {
								window.add_debug_log(route + "(\"" + selected_sku_id + "\");");
							}
							promise_resp = window.Module[route](selected_sku_id);
						} else {
							var parsed_route_data = JSON.parse(route_data);
							parsed_route_data.sku_id = selected_sku_id;
							if (window.debug_mode) {
								window.add_debug_log(route + "(\"" + JSON.stringify(parsed_route_data).replace(/"/g, '\\"') + "\");");
							}
							promise_resp = window.Module[route](JSON.stringify(parsed_route_data));
						}

					} catch (err) {
						if(err.constructor.name.includes("Error") != true){
							err = new Error(err)
						};
						err.name = "Captured error in Carousel menu onclick error at route=" + route + ", route_data=" + route_data + ", selected_sku_id=" + selected_sku_id + " : " + err.name;
						console.error(err);
						window.sentry_capture_exception("",err);
						alert.error("Something went wrong")
						set_page_loader({
							show: false,
							text: 'Please wait'
						});
						return;
					}
					var response = await window.Promisify(promise_resp);

					handle_ui_response(response);
					if (panel_pop_up_show) {
						repopulate_panel_pop_up();
					} else {
						repopulate_panel();
					}
					// is_search_mode ? populate_panel_search(search_string_panel) : ''
					update_carousel_minimize(true);
					update_view();

					if (perspective == '3d') {
						window.three_interface.mousemove(e)
					}
					else {
						window.two_interface.mousemove(e)
					}

					set_page_loader({
						show: false,
						text: 'Please Wait...'
					})
				}, 0)
			}
			
		}

		// useEffect(() => {
		// 	if(selected_sku_id){
		// 		var x = sku_json && sku_json.filter((element) => element.id===selected_sku_id )[0]
		// 		set_selected_sku(x)
		// 	}
		// },[selected_sku_id])

		// useEffect(() => {
		// 	if(selected_sku){
		// 	}
		// },[selected_sku])

		// useEffect(() => {
		// 	if(sku_json && sku_json.length){
		// 		set_selected_sku_id(sku_json[0].id)
		// 	}
		// },[sku_json])

		return (
			<div key={idx} className="wizard_slider_container"  style={{ ...container_style, position:'relative' }}>
				<LazyLoad offset={200} scroll={true} overflow={true}>
					<CarouselImageCard group_name={footer_text} sku_json={sku_json} onClick={on_click} callbackOnSkuChange={(sku) => set_selected_sku_id(sku && sku.id)}/>
				</LazyLoad>
					
				{!disable_overlay && (
					<div>
						{
							show_preview_loader?( <div className="wizard_slider_overlay wizard_slider_overlay_show" style={overlay_style}>
								<h6 className="wizard_slider_title" style={title_style} >Swapping Style...</h6>
									</div>):( <div className="wizard_slider_overlay" style={overlay_style}>
								<h6 className="wizard_slider_title" style={title_style} >{name}</h6>
							</div>)
						}
					</div>
				)}
			</div>
		)
	} catch(err) {
		//console.log('error in image box', err);
		return 'Image box failed'
	}
};

CarouselImageBox.defaultProps = {
	image_style : {},
	container_style: {},
	title_style: {},
	disable_overlay: false
};

export default CarouselImageBox;

{/* <div key={idx} className="wizard_slider_container" onClick={on_click} style={{ ...default_container_style, ...container_style, width:"100%" }}>
				{context_menu}
				<img
					style={{ ...default_image_style}}
					key={'horizonal-scroll-tempates'+idx}
					src={selected_sku && selected_sku.display_pic && selected_sku.display_pic.image ? build_path(window.Module.API.server_path, selected_sku.display_pic.image) : '/resources/images/INFURNIA LOGO white-10.svg'}
					alt={'Image Not Found'}
				/>
					
				<Row style={{width:'100%'}}>
					<Col>
						<Row style={{width:'100%'}}>
							<Col style={{ fontSize:'16px', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
								{footer_text?footer_text:''}
							</Col>
						</Row>
						<Row style={{width:'100%'}}>
							<Col style={{ fontSize:'12px', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
								<Input style={{ display:sku_json && sku_json.length && sku_json.length>1?'block':'none', height:'19px',width:'auto', fontSize:'13px', padding:'0px', borderRadius:'0px', color:'black', marginBottom:'5px'}} type="select" value={selected_sku_id} onChange={(e) => xyz(e)}>
									{
										sku_json && sku_json.length && sku_json.length>1 && sku_json.map((element) => (
											<option value={element.id}>{element.name}</option>
										))
									}
								</Input>
							</Col>
						</Row>
					</Col>
				</Row>
					
				{!disable_overlay && (
					<div>
						{
							show_preview_loader?( <div className="wizard_slider_overlay wizard_slider_overlay_show" style={overlay_style}>
								<h6 className="wizard_slider_title" style={title_style} >Swapping Style...</h6>
									</div>):( <div className="wizard_slider_overlay" style={overlay_style}>
								<h6 className="wizard_slider_title" style={title_style} >{name}</h6>
							</div>)
						}
					</div>
				)}
			</div> */}
