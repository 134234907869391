import React, { useState } from 'react';
import {useTranslation} from 'react-i18next'

const NavigationInfo = ({perspective}) => {
    const {t} = useTranslation();
    const remote_view = window.is_remote_view();
    
    const [panel_info_setting, set_panel_info_setting] = useState('mouse');

    return (
        <div className='view_port_container' style={{ background: 'transparent', width: '100%', display:/*seller_specific ? 'none' : */'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <div className='view_port_heading mb-3 justify-content-between flex_property'>
                <div>{t(`Input mode:`)}</div>
                {/* <div style={{color: '#A7A8B2', textTransform: 'capitalize', textDecoration:'none', fontSize:'12px', cursor:'pointer'}} className='flex_property' onClick={() => {if(panel_info_setting == 'mouse'){set_panel_info_setting('trackpad')}else{set_panel_info_setting('mouse')}}}>{panel_info_setting} <i className='fa fa-caret-down ml-2'></i></div> */}
                <div className='flex_property'>
                    <img src='/resources/panel_icons_new/mouse.svg' width={'16px'} className='mr-1' style={{ cursor: 'pointer', opacity: panel_info_setting == 'mouse' ? 1 : 0.5 }} onClick={() => { if (panel_info_setting == 'mouse') { return } else { set_panel_info_setting('mouse') } }} />
                    <img src='/resources/panel_icons_new/trackpad.svg' width={'16px'} style={{ cursor: 'pointer', opacity: panel_info_setting == 'trackpad' ? 1 : 0.5 }} onClick={() => { if (panel_info_setting == 'mouse') { set_panel_info_setting('trackpad') } else { return } }} />
                </div>
            </div>

            <div className='view_port_info_container'>
                <div className='view_port_row'>
                    <div className='view_port_property'>{t(`Pan`)}</div>
                    {
                        panel_info_setting == 'mouse' ?
                            <div className='view_port_shortcut'>
                                <img src='/resources/panel_icons_new/shift key.svg' className='view_port_icons' />
                                <img src='/resources/panel_icons_new/plus.svg' className='view_port_icons' />
                                <img src='/resources/panel_icons_new/mouse middle click.svg' className='view_port_icons' />
                            </div>
                            :
                            <div className='view_port_shortcut'>
                                {/* <img src='/resources/panel_icons_new/shift key.svg' className='view_port_icons'/> */}
                                {/* <img src='/resources/panel_icons_new/plus.svg' className='view_port_icons'/> */}
                                {
                                    navigator.userAgent.toLowerCase().indexOf('mac') !== -1 ?
                                        <React.Fragment>
                                            <img src='/resources/panel_icons_new/trackpad_two_finger_press.svg' className='view_port_icons' />
                                            <div className='view_port_icon_text'>{t(` 2 finger click drag `)}</div>
                                        </React.Fragment>
                                        :
                                        <div className='view_port_icon_text'>{t(` Right Click in trackpad `)}</div>
                                }
                            </div>
                    }
                </div>

                {perspective == '3d' ? <div className='view_port_row' style={{ display: perspective == '3d' ? 'flex' : 'none' }}>
                    <div className='view_port_property'>{t(`Orbit`)}</div>
                    {
                        panel_info_setting == 'mouse' ?
                            <div className='view_port_shortcut'>
                                {/* <img src='/resources/panel_icons_new/shift key.svg' className='view_port_icons'/> */}
                                {/* <img src='/resources/panel_icons_new/plus.svg' className='view_port_icons'/> */}
                                <img src='/resources/panel_icons_new/mouse middle click.svg' className='view_port_icons' />
                                <div className='view_port_icon_text'>{t(` Middle mouse `)}</div>
                            </div>
                            :
                            <div className='view_port_shortcut'>
                                <img src='/resources/panel_icons_new/shift key.svg' className='view_port_icons' />
                                <img src='/resources/panel_icons_new/plus.svg' className='view_port_icons' />
                                <img src='/resources/panel_icons_new/trackpad_one_finger_press.svg' className='view_port_icons' />
                            </div>
                    }
                </div> : ''}

                {/* <div className='view_port_row'>
                    <div className='view_port_property'>{t(`Zoom`)}</div>
                    {
                        panel_info_setting == 'mouse' ?
                            <div className='view_port_shortcut'>
                                <img src='/resources/panel_icons_new/mouse scroll.svg' className='view_port_icons' />
                                <div className='view_port_icon_text'>{t(` Mouse Scroll `)}</div>
                            </div>
                            :
                            <div className='view_port_shortcut'>
                                <img src='/resources/panel_icons_new/trackpad_pinch.svg' className='view_port_icons' />
                                <div className='view_port_icon_text'>{t(` Pinch / swipe `)}</div>
                            </div>
                    }
                </div> */}
                {/* <div className='view_port_info_text'>To Zoom and pass through objects quickly, hold down SHIFT key</div> */}
            </div>
            
            <div className='view_port_info_container'>
                <div className='view_port_text_style_2'>{t(`Zoom Options`)}</div>
                <div className='view_port_row'>
                    <div className='view_port_property' style={{ fontSize: '12px' }}>{t(`Manual`)}</div>
                    {
                        panel_info_setting == 'mouse' ?
                            <div className='view_port_shortcut'>
                                <img src='/resources/panel_icons_new/mouse scroll.svg' className='view_port_icons' />
                                <div className='view_port_icon_text'>{t(` Mouse Scroll `)}</div>
                            </div>
                            :
                            <div className='view_port_shortcut'>
                                <img src='/resources/panel_icons_new/trackpad_pinch.svg' className='view_port_icons' />
                                <div className='view_port_icon_text'>{t(` Pinch / swipe `)}</div>
                            </div>
                    }
                </div>

                {
                    remote_view ? ''
                        :
                        <>
                            <div className='view_port_row'>
                                <div className='view_port_property' style={{ fontSize: '12px' }}>{t(`To selection`)}</div>
                                <div className='view_port_shortcut'>
                                    <img src='/resources/panel_icons_new/shift key.svg' className='view_port_icons' />
                                    <img src='/resources/panel_icons_new/plus.svg' className='view_port_icons' />
                                    <img src='/resources/panel_icons_new/x.svg' />
                                </div>
                            </div>

                            <div className='view_port_row'>
                                <div className='view_port_property' style={{ fontSize: '12px' }}>{t(`To fit all`)}</div>
                                <div className='view_port_shortcut'>
                                    <img src='/resources/panel_icons_new/shift key.svg' className='view_port_icons' />
                                    <img src='/resources/panel_icons_new/plus.svg' className='view_port_icons' />
                                    <img src='/resources/panel_icons_new/z.svg' />
                                </div>
                            </div>
                        </>
                }
            </div>

            <div className='view_port_info_container' style={{ display: perspective == '3d' ? 'block' : 'none' }}>
                {/* <div className='view_port_row'> */}
                <div className='view_port_text_style_2'>{t(`Move Camera`)}</div>
                {/* </div> */}
                <div className='view_port_row'>
                    <div className='view_port_shortcut'>
                        <img src='/resources/panel_icons_new/shift key.svg' className='view_port_icons' style={{ paddingBottom: '12px' }} />
                        <img src='/resources/panel_icons_new/plus.svg' className='view_port_icons' style={{ paddingBottom: '12px' }} />
                        {/* <img src='/resources/panel_icons_new/w.svg' className='view_port_icons' />
                        <img src='/resources/panel_icons_new/a.svg' className='view_port_icons' />
                        <img src='/resources/panel_icons_new/s.svg' className='view_port_icons' />
                        <img src='/resources/panel_icons_new/d.svg' className='view_port_icons' /> */}
                        <div className='view_port_icons'>
                            <img src='/resources/panel_icons_new/w.svg' />
                            <div className='view_port_text_under_icon'>{t(`Front`)}</div>
                        </div>
                        <div className='view_port_icons'>
                            <img src='/resources/panel_icons_new/a.svg' />
                            <div className='view_port_text_under_icon'>{t(`Left`)}</div>
                        </div>
                        <div className='view_port_icons'>
                            <img src='/resources/panel_icons_new/s.svg' />
                            <div className='view_port_text_under_icon'>{t(`Back`)}</div>
                        </div>
                        <div className='view_port_icons'>
                            <img src='/resources/panel_icons_new/d.svg' />
                            <div className='view_port_text_under_icon'>{t(`Right`)}</div>
                        </div>
                    </div>
                </div>
                <div className='view_port_row'>
                    <div className='view_port_shortcut'>
                        <img src='/resources/panel_icons_new/shift key.svg' className='view_port_icons' style={{ paddingBottom: '12px' }} />
                        <img src='/resources/panel_icons_new/plus.svg' className='view_port_icons' style={{ paddingBottom: '12px' }} />
                        {/* <img src='/resources/panel_icons_new/q.svg' className='view_port_icons' />
                        <img src='/resources/panel_icons_new/e.svg' className='view_port_icons'/> */}
                        <div className='view_port_icons'>
                            <img src='/resources/panel_icons_new/q.svg' />
                            <div className='view_port_text_under_icon'>{t(`Up`)}</div>
                        </div>
                        <div className='view_port_icons'>
                            <img src='/resources/panel_icons_new/e.svg' />
                            <div className='view_port_text_under_icon'>{t(`Down`)}</div>
                        </div>
                    </div>
                </div>
                {/* <div className='view_port_row'> */}
                <div className='view_port_text_style_2'>{t(`Rotate Camera`)}</div>
                {/* </div> */}
                <div className='view_port_row'>
                    <div className='view_port_shortcut'>
                        <img src='/resources/panel_icons_new/shift key.svg' className='view_port_icons' style={{ paddingBottom: '12px' }} />
                        <img src='/resources/panel_icons_new/plus.svg' className='view_port_icons' style={{ paddingBottom: '12px' }} />
                        <div className='view_port_icons'>
                            <img src='/resources/panel_icons_new/up.svg' />
                            <div className='view_port_text_under_icon'>{t(`Up`)}</div>
                        </div>
                        <div className='view_port_icons'>
                            <img src='/resources/panel_icons_new/down.svg' />
                            <div className='view_port_text_under_icon'>{t(`Down`)}</div>
                        </div>
                        <div className='view_port_icons'>
                            <img src='/resources/panel_icons_new/left.svg' />
                            <div className='view_port_text_under_icon'>{t(`Left`)}</div>
                        </div>
                        <div className='view_port_icons'>
                            <img src='/resources/panel_icons_new/right.svg' />
                            <div className='view_port_text_under_icon'>{t(`Right`)}</div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className='view_port_row' style={{ display: perspective == '3d' ? 'flex' : 'none' }}>
                <div className='view_port_property'>Move:</div>
                <div className='view_port_shortcut'>
                    <img src='/resources/panel_icons_new/shift key.svg' className='view_port_icons' />
                    <img src='/resources/panel_icons_new/plus.svg' className='view_port_icons' />
                    <img src='/resources/panel_icons_new/w.svg' className='view_port_icons' />
                    <img src='/resources/panel_icons_new/a.svg' className='view_port_icons' />
                    <img src='/resources/panel_icons_new/s.svg' className='view_port_icons' />
                    <img src='/resources/panel_icons_new/d.svg' className='view_port_icons' />
                </div>
            </div> */}

            {/* <div className='view_port_row' style={{ display: perspective == '3d' ? 'flex' : 'none' }}>
                <div className='view_port_property'>Up / Down</div>
                <div className='view_port_shortcut'>
                    <img src='/resources/panel_icons_new/c.svg' className='view_port_icons' />
                    <img src='/resources/panel_icons_new/space key.svg' className='view_port_icons' />
                </div>
            </div> */}


            {/* <div className='view_port_row' style={{ display: perspective == '3d' ? 'flex' : 'none' }}>
                <div className='view_port_property'>Rotate:</div>
                <div className='view_port_shortcut'>
                    <img src='/resources/panel_icons_new/shift key.svg' className='view_port_icons' />
                    <img src='/resources/panel_icons_new/plus.svg' className='view_port_icons' />
                    <img src='/resources/panel_icons_new/up.svg' className='view_port_icons' />
                    <img src='/resources/panel_icons_new/down.svg' className='view_port_icons' />
                    <img src='/resources/panel_icons_new/right.svg' className='view_port_icons' />
                    <img src='/resources/panel_icons_new/left.svg' className='view_port_icons' />
                </div>
            </div> */}
        </div>
    )
}

export default NavigationInfo